import truncate from "lodash-es/truncate";

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type TruncateOptions = {
  length: number;
  separator?: string | RegExp;
  omission?: string;
};

// need to add a type-guards isRegExp in core
export function isTruncateOptions(value: unknown): value is TruncateOptions {
  const truncateObject = value as TruncateOptions;
  return (
    isObject(truncateObject) &&
    isNumber(truncateObject.length) &&
    (isString(truncateObject.separator) || !isDefined(truncateObject.separator)) &&
    (isString(truncateObject.omission) || !isDefined(truncateObject.omission))
  );
}

export function truncateText(text: string, options: TruncateOptions): string | undefined {
  if (!isTruncateOptions(options)) {
    return;
  }
  return truncate(text, { ...options });
}
